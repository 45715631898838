var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.courseValues)?_c('CourseHeader',{staticClass:"mb-6 mt-3",attrs:{"course":_vm.courseValues}}):_vm._e(),(_vm.schedule)?_c('BaseTableFiltered',{attrs:{"headers":_vm.headers,"items":_vm.schedule,"search":_vm.search},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"7"}},[_c('p',{staticClass:"mb-1 title font-weight-light align-self"},[_vm._v("Fremmøte")])]),(!_vm.courseHasParticipants)?_c('v-col',{staticClass:"py-0 d-flex justify-end align-center",attrs:{"cols":"5"}},[_c('p',{staticClass:"mb-0 mx-2 subtitle-1"},[_vm._v("Kurset har ingen deltakere")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.isCourseDone},on:{"click":_vm.navigateToParticipantList}},[_vm._v(" Legg til deltakere ")])],1):_vm._e(),_c('v-col',{staticClass:"py-0 d-flex align-end",attrs:{"cols":"6"}},[_c('dl',{staticClass:"px-3 subtitle-1 font-weight-light justify-space-between flex-direction: row"},[_c('dt',[_vm._v("Fullførte timer:")]),_c('dd',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.courseValues.completedScheduledHours))]),_c('dt',[_vm._v("Gjenstående timer:")]),_c('dd',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.courseValues.remainingScheduledHours))])])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"pa-0 ma-0 mt-2",attrs:{"append-icon":"mdi-magnify","label":"Søk","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.end",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(value))+" ")]}},{key:"item.isAllAttendancesRegistered",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value && _vm.courseHasParticipants ? "Ja" : "Nei")+" ")]}},{key:"item.hourAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDifferenceInHours(item))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"openCourseAttendance"},on:{"click":function($event){return _vm.openCourseAttendanceParticipant(item.start)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("Åpne dag")])])]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }