































































import { api } from "@/api/api";
import { ApiGetCourseScheduleDtoType, ApiGetKursAdminCourseDto } from "@/api/generated/Api";
import CourseHeader from "@/components/course/details/CourseHeader.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { formatDateTime } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRoute, useRouter } from "@/shared/useHelpers";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import isBefore from "date-fns/isBefore";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";

export default defineComponent({
  name: "CourseAttendancePage",
  components: {
    CourseHeader,
    BaseTableFiltered,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const search = ref("");
    const schedule = computed(() =>
      courseValues.value?.plan?.schedules?.filter(
        (scheduleEntry) => scheduleEntry.start && isBefore(new Date(scheduleEntry?.start), new Date())
      )
    );
    const courseHasParticipants = ref(false);
    const courseValues = ref<ApiGetKursAdminCourseDto>();

    const openCourseAttendanceParticipant = (startDate: string) => {
      router.push({
        name: SingleCourseRouteNames.CourseAttendanceParticipant,
        params: {
          id: route.params.id,
          startDate,
        },
      });
    };

    const navigateToParticipantList = () => {
      router.push({
        name: SingleCourseRouteNames.CourseParticipants,
        params: {
          id: route.params.id,
        },
      });
    };

    const checkIfCourseParticipants = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        courseHasParticipants.value =
          (await api.course.getCourseParticipantsByCourseIdAsync(+route.params.id)).data.length > 1;
      });
    };

    const getCourse = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        courseValues.value = (await api.course.getCourseByIdFilteredByDepartmentAsync(+route.params.id)).data;
      });
    };

    onMounted(async () => {
      await Promise.all([getCourse(), checkIfCourseParticipants()]);
    });
    const getDifferenceInHours = (courseEvent: ApiGetCourseScheduleDtoType) => {
      const diffHours = Math.abs(new Date(courseEvent.end).getTime() - new Date(courseEvent.start).getTime()) / 36e5;

      return diffHours.toFixed(1);
    };

    return {
      headers,
      search,
      schedule,
      courseValues,
      isCourseDone: computed(() => courseValues.value?.status === CourseStatus.Closed),
      openCourseAttendanceParticipant,
      getDifferenceInHours,
      formatDateTime,
      navigateToParticipantList,
      courseHasParticipants,
    };
  },
});

const headers = [
  {
    text: "Dato",
    value: "end",
  },
  {
    text: "Tittel",
    value: "title",
  },
  {
    text: "Alt fremmøte registrert",
    value: "isAllAttendancesRegistered",
  },
  {
    text: "Antall timer",
    value: "hourAmount",
  },
  {
    text: "Handlinger",
    value: "actions",
  },
];
